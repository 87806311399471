import qs from 'qs'

export const queryProgramEntryThumbnails = (options: QueryManyOptions) => {
  const query = qs.stringify(
    {
      publicationState: options?.publicationState || 'live',
      sort: options?.sort || ['date_time_start:desc', 'date_time_end:asc'],
      filters: options?.filters || [],
      pagination: {
        page: options?.page || 1,
        pageSize: options?.pageSize || 4,
      },
      ...thumbnailProgramEntry
    },
    {
      encodeValuesOnly: true
    }
  )

  return `program-entries?${query}`
}
